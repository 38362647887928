import { useAuth0 } from '@auth0/auth0-react';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BrushIcon from '@mui/icons-material/Brush';
import BusinessIcon from '@mui/icons-material/Business';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import GroupIcon from '@mui/icons-material/Group';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import LogoDevIcon from '@mui/icons-material/LogoDev';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import TuneIcon from '@mui/icons-material/Tune';
import {
  Badge,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import ResponsiveDialog from '../../components/ResponsiveDialog/ResponsiveDialog';
import { appActions } from '../../features/app/app-slice';
import BankAccount from '../../features/bank-account/BankAccount';
import Profile from '../../features/profile/Profile';
import Registrar from '../../features/registrar/Registrar';
import Documents from '../../features/settings/Documents';
import SupplierAPIClients from '../../features/settings/SupplierAPIClients';
import SupplierPersonalization from '../../features/settings/SupplierPersonalization';
import SupplierSettings from '../../features/settings/SupplierSettings';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import useCompanyStatus from '../../hooks/useCompanyStatus';
import useUserGrant from '../../hooks/useUserGrant';
import useUserRole from '../../hooks/useUserRole';
import NotificationSettings from '../settings/NotificationSettings';
import SupplierUserSettings from '../settings/SupplierUserSettings';

type ProfileMenuProps = {
  anchorEl: HTMLButtonElement | null;
  onMenuClose: () => void;
};

export const ProfileMenu = ({ anchorEl, onMenuClose }: ProfileMenuProps) => {
  const { logout, user } = useAuth0();
  const dispatch = useAppDispatch();

  const isOpen = Boolean(anchorEl);
  const {
    company,
    isProfileOpen,
    isDocumentsOpen,
    isSupplierSettingsOpen,
    isNotificationSettingsOpen,
    isSupplierUserSettingsOpen,
    isSupplierPersonalizationOpen,
    isBankAccountPanelOpen,
    isRegistrarPanelOpen,
    isSupplierAPIClientsPanelOpen,
  } = useAppSelector((state) => state.app);
  const {
    documentsOk,
    profileOk,
    bankAccountOk,
    targetBankAccountOk,
    retailerOptinOk,
    supplierSettingsOk,
  } = useCompanyStatus();

  const { isAdmin } = useUserRole();
  const showDocuments = useUserGrant(['supplier-document-view']);
  const showBankAccount = useUserGrant(['supplier-bankaccount']);
  const showSupplierUsers = useUserGrant(['supplier-user']);

  const handleProfileClick = () => {
    dispatch(appActions.setIsProfileOpen(true));
    onMenuClose();
  };
  const handleDocumentsClick = () => {
    dispatch(appActions.setIsDocumentsOpen(true));
    onMenuClose();
  };
  const handleSupplierSettingsClick = () => {
    dispatch(appActions.setIsSupplierSettingsOpen(true));
    onMenuClose();
  };
  const handleNotificationSettingsClick = () => {
    dispatch(appActions.setIsNotificationSettingsOpen(true));
    onMenuClose();
  };
  const handleSupplierUserSettingsClick = () => {
    dispatch(appActions.setIsSupplierUserSettingsOpen(true));
    onMenuClose();
  };
  const handleSupplierPersonalizationClick = () => {
    dispatch(appActions.setIsSupplierPersonalizationOpen(true));
    onMenuClose();
  };
  const handleSupplierAPIClientsClick = () => {
    dispatch(appActions.setIsSupplierAPIClientsPanelOpen(true));
    onMenuClose();
  };
  const handleBankAccountClick = () => {
    dispatch(appActions.setIsBankAccountPanelOpen(true));
    onMenuClose();
  };
  const handleRetailerOptinClick = () => {
    dispatch(appActions.setIsRegistrarPanelOpen(true));
    onMenuClose();
  };

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: process.env.REACT_APP_AUTH0_LOGOUT_URL,
      },
    });
  };

  return (
    <>
      <Menu
        open={isOpen}
        onClose={() => onMenuClose()}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {user && company && <MenuItem disabled>{user?.email}</MenuItem>}
        {company && (
          <MenuItem onClick={handleProfileClick}>
            <ListItemIcon>
              <Badge invisible={profileOk} badgeContent="!" color="error">
                <BusinessIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText primary="Perfil" />
          </MenuItem>
        )}
        {company && showDocuments && (
          <MenuItem onClick={handleDocumentsClick}>
            <ListItemIcon>
              <Badge invisible={documentsOk} badgeContent="!" color="error">
                <InsertDriveFileIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText primary="Documentos" />
          </MenuItem>
        )}
        {company &&
          (isAdmin ||
            (company.companyType === 'supplier' &&
              company.onboardingApproved &&
              showBankAccount)) && (
            <MenuItem onClick={handleBankAccountClick}>
              <ListItemIcon>
                <Badge
                  invisible={bankAccountOk && targetBankAccountOk}
                  badgeContent="!"
                  color="error"
                >
                  <AccountBalanceIcon />
                </Badge>
              </ListItemIcon>
              <ListItemText primary="Conta" />
            </MenuItem>
          )}
        {company && isAdmin && company.companyType === 'supplier' && (
          <MenuItem onClick={handleSupplierSettingsClick}>
            <ListItemIcon>
              <Badge
                invisible={supplierSettingsOk}
                badgeContent="!"
                color="error"
              >
                <TuneIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText primary="Configurações" />
          </MenuItem>
        )}
        {company && isAdmin && company.companyType === 'supplier' && (
          <MenuItem onClick={handleNotificationSettingsClick}>
            <ListItemIcon>
              <EditNotificationsIcon />
            </ListItemIcon>
            <ListItemText primary="Notificações" />
          </MenuItem>
        )}
        {company && company.companyType === 'supplier' && showSupplierUsers && (
          <MenuItem onClick={handleSupplierUserSettingsClick}>
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary="Usuários" />
          </MenuItem>
        )}
        {company && isAdmin && company.companyType === 'supplier' && (
          <MenuItem onClick={handleSupplierAPIClientsClick}>
            <ListItemIcon>
              <LogoDevIcon />
            </ListItemIcon>
            <ListItemText primary="API" />
          </MenuItem>
        )}

        {company && isAdmin && company.companyType === 'supplier' && (
          <MenuItem onClick={handleSupplierPersonalizationClick}>
            <ListItemIcon>
              <BrushIcon />
            </ListItemIcon>
            <ListItemText primary="Personalização" />
          </MenuItem>
        )}

        {company && isAdmin && company.companyType === 'retailer' && (
          <MenuItem onClick={handleRetailerOptinClick}>
            <ListItemIcon>
              <Badge invisible={retailerOptinOk} badgeContent="!" color="error">
                <MenuBookIcon />
              </Badge>
            </ListItemIcon>
            <ListItemText primary="Registradora" />
          </MenuItem>
        )}

        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </MenuItem>
        {/* {isDebugActivated && (
            <Link
              target="_blank"
              rel="noopener noreferrer"
              variant="caption"
              color="textSecondary"
              href={getFirestoreConsoleLinkReceivablesQuery(
                company?.docNumber ?? ''
              )}
            >
              Meus recebíveis
            </Link>
          )} */}
      </Menu>
      <ResponsiveDialog
        open={isProfileOpen}
        maxWidth="xs"
        fullWidth
        onClose={() => dispatch(appActions.setIsProfileOpen(false))}
      >
        <Profile />
      </ResponsiveDialog>
      <ResponsiveDialog
        open={isDocumentsOpen}
        maxWidth="sm"
        fullWidth
        onClose={() => dispatch(appActions.setIsDocumentsOpen(false))}
      >
        <Documents />
      </ResponsiveDialog>
      <ResponsiveDialog
        open={isSupplierSettingsOpen}
        maxWidth="md"
        fullWidth
        onClose={() => dispatch(appActions.setIsSupplierSettingsOpen(false))}
      >
        <SupplierSettings />
      </ResponsiveDialog>
      <ResponsiveDialog
        open={isNotificationSettingsOpen}
        maxWidth="md"
        fullWidth
        onClose={() =>
          dispatch(appActions.setIsNotificationSettingsOpen(false))
        }
      >
        <NotificationSettings />
      </ResponsiveDialog>
      <ResponsiveDialog
        open={isSupplierUserSettingsOpen}
        maxWidth="sm"
        fullWidth
        onClose={() =>
          dispatch(appActions.setIsSupplierUserSettingsOpen(false))
        }
      >
        <SupplierUserSettings />
      </ResponsiveDialog>
      <ResponsiveDialog
        open={isSupplierPersonalizationOpen}
        maxWidth="md"
        fullWidth
        onClose={() =>
          dispatch(appActions.setIsSupplierPersonalizationOpen(false))
        }
      >
        <SupplierPersonalization />
      </ResponsiveDialog>
      <ResponsiveDialog
        open={isBankAccountPanelOpen}
        maxWidth={company?.companyType === 'supplier' ? 'md' : 'sm'}
        fullWidth
        onClose={() => dispatch(appActions.setIsBankAccountPanelOpen(false))}
      >
        <BankAccount />
      </ResponsiveDialog>
      <ResponsiveDialog
        open={isSupplierAPIClientsPanelOpen}
        maxWidth="md"
        fullWidth
        onClose={() =>
          dispatch(appActions.setIsSupplierAPIClientsPanelOpen(false))
        }
      >
        <SupplierAPIClients />
      </ResponsiveDialog>
      <ResponsiveDialog
        open={isRegistrarPanelOpen}
        maxWidth="sm"
        fullWidth
        onClose={() => dispatch(appActions.setIsRegistrarPanelOpen(false))}
      >
        <Registrar />
      </ResponsiveDialog>
    </>
  );
};
