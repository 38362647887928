import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import RefreshIcon from '@mui/icons-material/Refresh';

import { LoadingButton } from '@mui/lab';

import { Box, IconButton, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import ConfirmationDialog from '../../components/ConfirmationDialog/ConfirmationDialog';
import EditForm from '../../components/EditForm/EditForm';
import EditFormAction from '../../components/EditForm/EditFormAction';
import EditFormContent from '../../components/EditForm/EditFormContent';
import EditFormHeader from '../../components/EditForm/EditFormHeader';
import { getFirestoreConsoleLink } from '../../helpers/firebase';
import { format } from '../../helpers/format';
import { useAppDispatch, useAppSelector } from '../../helpers/hooks';
import FirebaseIcon from '../../icons/FirebaseIcon';
import { appActions } from '../app/app-slice';
import { registrarActions } from './registrar-slice';

type DetailProps = {
  label: string;
  value?: string;
  children?: React.ReactNode;
};
const Detail = ({ label, value, children }: DetailProps) => (
  <Box>
    <Typography variant="body2" color="textSecondary" component="p">
      {label}:
    </Typography>
    {value && (
      <Typography variant="body1" color="textPrimary" component="p">
        {' '}
        {value ?? '-'}
      </Typography>
    )}
    {children}
  </Box>
);

export default function Registrar() {
  const dispatch = useAppDispatch();
  const { retailerOptin } = useAppSelector((state) => state.app);
  const { isDebugActivated } = useAppSelector((state) => state.devTools);
  const { isLoading } = useAppSelector((state) => state.admin);

  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [isCancelConfirmationOpen, setIsCancelConfirmationOpen] =
    useState(false);
  const [isPostponeConfirmationOpen, setIsPostponeConfirmationOpen] =
    useState(false);

  useEffect(() => {
    if (retailerOptin)
      dispatch(registrarActions.getRetailerOptin(retailerOptin.id));
  }, []);

  const handleCloseClick = () => {
    dispatch(appActions.setIsRegistrarPanelOpen(false));
  };
  const handleCreateClick = () => {
    setIsConfirmationOpen(true);
  };
  const handleConfirmClick = () => {
    dispatch(registrarActions.createRetailerOptin());
    setIsConfirmationOpen(false);
  };
  const handleCancelClick = () => {
    setIsCancelConfirmationOpen(true);
  };
  const handleCancelConfirmClick = () => {
    if (retailerOptin?.id) {
      dispatch(registrarActions.cancelRetailerOptin(retailerOptin.id));
      setIsCancelConfirmationOpen(false);
    }
  };
  const handlePostponeClick = () => {
    setIsPostponeConfirmationOpen(true);
  };
  const handlePostponeConfirmClick = () => {
    if (retailerOptin?.id) {
      dispatch(
        registrarActions.updateRetailerOptin({
          id: retailerOptin.id,
          body: {
            endDate: dayjs(retailerOptin.endDate)
              .add(3, 'month')
              .format('YYYY-MM-DD'),
          },
        })
      );
      setIsPostponeConfirmationOpen(false);
    }
  };

  return (
    <EditForm>
      <EditFormHeader
        title="Registradora"
        onCloseClick={handleCloseClick}
        actions={
          isDebugActivated && (
            <IconButton
              target="_blank"
              rel="noopener noreferrer"
              href={getFirestoreConsoleLink(
                `/retailer-optins/${retailerOptin?.id}`
              )}
            >
              <FirebaseIcon />
            </IconButton>
          )
        }
      />
      <EditFormContent
        display="flex"
        flexDirection="row"
        gap={2}
        paddingBottom={2}
      >
        <Stack direction="column" gap={2}>
          {retailerOptin ? (
            <>
              <Stack direction="row" alignItems="center" gap={1}>
                <Typography>Dados do Optin</Typography>

                <IconButton
                  onClick={() =>
                    dispatch(registrarActions.refreshRetailerOptins())
                  }
                >
                  <RefreshIcon />
                </IconButton>
              </Stack>
              <Detail label="ID" value={retailerOptin.id} />
              <Detail
                label="ID Registradora"
                value={retailerOptin.registrarExternalId}
              />
              <Detail
                label="Data da assinatura"
                value={format(retailerOptin.signatureDate, 'date')}
              />
              <Detail
                label="Vigência"
                value={`${format(retailerOptin.startDate, 'date')} a ${format(
                  retailerOptin.endDate,
                  'date'
                )}`}
              />
              <Detail label="Status" value={retailerOptin.status} />
            </>
          ) : (
            <>
              <Stack direction="row" alignItems="center" gap={1}>
                <Typography>Nenhum optin registrado</Typography>
                <IconButton
                  onClick={() =>
                    dispatch(registrarActions.refreshRetailerOptins())
                  }
                >
                  <RefreshIcon />
                </IconButton>
              </Stack>
            </>
          )}
        </Stack>
      </EditFormContent>
      <EditFormAction>
        {retailerOptin ? (
          <Stack direction="row" gap={1}>
            <LoadingButton
              loading={isLoading}
              loadingPosition="start"
              startIcon={<CancelIcon />}
              variant="outlined"
              color="error"
              onClick={handleCancelClick}
            >
              Cancelar
            </LoadingButton>
            <LoadingButton
              loading={isLoading}
              loadingPosition="start"
              startIcon={<EditCalendarIcon />}
              variant="contained"
              onClick={handlePostponeClick}
            >
              Prorrogar
            </LoadingButton>
          </Stack>
        ) : (
          <LoadingButton
            loading={isLoading}
            loadingPosition="start"
            startIcon={<AddIcon />}
            variant="outlined"
            onClick={handleCreateClick}
          >
            Criar novo Optin
          </LoadingButton>
        )}
      </EditFormAction>
      <ConfirmationDialog
        open={isConfirmationOpen}
        title="Confirmação"
        actions={[
          {
            name: 'Cancelar',
            buttonProps: {
              autoFocus: true,
              onClick: () => {
                setIsConfirmationOpen(false);
              },
            },
          },
          {
            name: 'Confirmar',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              startIcon: <CheckIcon />,
              onClick: handleConfirmClick,
            },
          },
        ]}
      >
        Deseja criar um optin na registradora?
      </ConfirmationDialog>
      <ConfirmationDialog
        open={isCancelConfirmationOpen}
        title="Confirmação"
        actions={[
          {
            name: 'Não',
            buttonProps: {
              autoFocus: true,
              onClick: () => {
                setIsCancelConfirmationOpen(false);
              },
            },
          },
          {
            name: 'Confirmar Cancelamento',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              startIcon: <CheckIcon />,
              onClick: handleCancelConfirmClick,
            },
          },
        ]}
      >
        Deseja cancelar o optin na registradora? Não será mais possível realizar
        operações com esse cliente.
      </ConfirmationDialog>
      <ConfirmationDialog
        open={isPostponeConfirmationOpen}
        title="Confirmação"
        actions={[
          {
            name: 'Não',
            buttonProps: {
              autoFocus: true,
              onClick: () => {
                setIsPostponeConfirmationOpen(false);
              },
            },
          },
          {
            name: 'Confirmar',
            showLoading: true,
            buttonProps: {
              variant: 'contained',
              startIcon: <EditCalendarIcon />,
              onClick: handlePostponeConfirmClick,
            },
          },
        ]}
      >
        Deseja prorrogar o optin na registradora?
      </ConfirmationDialog>
    </EditForm>
  );
}
