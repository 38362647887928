import { Models, Service, Supplier } from 'types';
import { apiCall } from '../helpers/api';

const service: Service = 'supplier';
const resource = '/web/registrar';

export class WebRegistrarHelpers {
  public static refreshRetailerOptins =
    async (): Promise<Models.RetailerOptin> => {
      const retailerOptin = await apiCall<Models.RetailerOptin>({
        service,
        resource: `${resource}/retailer-optin`,
      });
      return retailerOptin;
    };
  public static getRetailerOptinById = async (
    id: string
  ): Promise<Models.RetailerOptin> => {
    const retailerOptin = await apiCall<Models.RetailerOptin>({
      service,
      resource: `${resource}/retailer-optin/${id}`,
    });
    return retailerOptin;
  };

  public static updateRetailerOptinById = async (
    id: string,
    payload: Supplier.PatchOptinPayload
  ): Promise<Models.RetailerOptin> => {
    const retailerOptin = await apiCall<Models.RetailerOptin>({
      service,
      resource: `${resource}/retailer-optin/${id}`,
      payload,
      kyOpts: {
        method: 'patch',
      },
    });
    return retailerOptin;
  };

  public static createRetailerOptin =
    async (): Promise<Models.RetailerOptin> => {
      const created = await apiCall<Models.RetailerOptin>({
        service,
        resource: `${resource}/retailer-optin`,
        kyOpts: {
          method: 'post',
        },
      });
      return created;
    };

  public static cancelRetailerOptin = async (id: string) => {
    await apiCall({
      service,
      resource: `${resource}/retailer-optin/${id}/cancel`,
      kyOpts: {
        method: 'post',
      },
    });
  };
}
