import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import AddIcon from '@mui/icons-material/Add';
import PaymentIcon from '@mui/icons-material/Payment';
import { Button, Link } from '@mui/material';
import { useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { CompanyStatus } from '../../../components/CompanyStatus/CompanyStatus';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import DataTable from '../../../components/Table/DataTable';
import { TableColumn } from '../../../components/Table/table.types';
import { getCompanyStatus } from '../../../helpers/company-status';
import {
  getFirestoreConsoleLink,
  getFirestoreConsoleLinkOptinsQuery,
} from '../../../helpers/firebase';
import { useAppDispatch, useAppSelector } from '../../../helpers/hooks';
import useUserGrant from '../../../hooks/useUserGrant';
import { ListaVarejista, ListaVarejistaStatusConvite } from '../../../types';
import { FABNewPaymentRequest } from '../components/FABNewPaymentRequest';
import { supplierActions } from '../supplier.slice';

function Clientes() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { retailers, invites, isLoadingRetailers } = useAppSelector(
    (state) => state.supplier
  );
  const company = useAppSelector((state) => state.app.company);
  const isDebugActivated = useAppSelector(
    (state) => state.devTools.isDebugActivated
  );
  const { isOperatingHours } =
    useAppSelector((state) => state.app.operatingHours) ?? {};
  const showNewButton = useUserGrant(['supplier-customer-add']);
  const showTransactions = useUserGrant(['supplier-customer-transactions']);
  const showSchedule = useUserGrant([
    'supplier-customer-schedule-payments-view',
  ]);

  const columns: TableColumn<ListaVarejista>[] = [
    {
      id: 'idRetailer',
      label: 'id',
      hidden: !isDebugActivated,
      renderer: (v, row) => (
        <>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            variant="caption"
            color="textSecondary"
            href={getFirestoreConsoleLink(`/companies/${v}`)}
          >
            {v}
          </Link>
          <Link
            target="_blank"
            rel="noopener noreferrer"
            variant="caption"
            color="textSecondary"
            href={getFirestoreConsoleLinkOptinsQuery(
              row.docNumber,
              company?.docNumber ?? ''
            )}
          >
            (Optins)
          </Link>
        </>
      ),
    },
    { id: 'docNumber', label: 'CPF/CNPJ', type: 'docNumber', flex: 0.3 },
    { id: 'nomeVarejista', label: 'Nome' },
    {
      id: 'statusConvite',
      label: 'Status',
      center: true,
      flex: 0.3,

      renderer: (status: ListaVarejistaStatusConvite | undefined) => (
        <CompanyStatus
          status={status?.status}
          idInvite={status?.invite?.id}
          inviteEmail={status?.invite?.email}
        />
      ),
    },
    {
      id: 'acaoAgenda',
      label: 'Agenda',
      center: true,
      flex: 0.3,
      hidden: !showSchedule,
      renderer: (_v, row: ListaVarejista) =>
        row.statusConvite.status === 'Ativo' ? (
          <NavLink to={`${row.idRetailer}/agenda`}>
            <Button variant="outlined" size="small" startIcon={<PaymentIcon />}>
              Agenda
            </Button>
          </NavLink>
        ) : (
          <></>
        ),
    },
    {
      id: 'acaoExtrato',
      label: 'Extrato',
      center: true,
      flex: 0.3,
      hidden: !showTransactions,
      renderer: (_v, row: ListaVarejista) =>
        row.statusConvite.status === 'Ativo' ? (
          <NavLink to={`${row.idRetailer}/extrato`}>
            <Button
              variant="outlined"
              size="small"
              startIcon={<AccountBalanceWalletIcon />}
            >
              Extrato
            </Button>
          </NavLink>
        ) : (
          <></>
        ),
    },
  ];

  const enhancedData: ListaVarejista[] = [
    ...retailers.map((c) => ({
      idRetailer: c.id,
      docNumber: c.docNumber,
      nomeVarejista: c.name,
      statusConvite: {
        status: getCompanyStatus(c),
        invite: invites.find((v) => v.idCompany === c.id),
      },
      acaoAgenda: 'agenda-1',
      acaoPagamento: 'pagamento-1',
      acaoLiquidacoes: 'liquidacoes-1',
      acaoExtrato: 'extrato-1',
    })),
  ].sort(({ nomeVarejista: a }, { nomeVarejista: b }) => a.localeCompare(b));
  useEffect(() => {
    dispatch(supplierActions.loadRetailers());
  }, []);

  const addCompanyClick = () => {
    navigate('./novo');
  };
  return (
    <>
      <PageHeader
        title="Clientes"
        actions={
          showNewButton && (
            <Button
              variant="contained"
              disabled={!isOperatingHours}
              onClick={addCompanyClick}
              startIcon={<AddIcon />}
            >
              Novo
            </Button>
          )
        }
      />
      <DataTable<ListaVarejista>
        columns={columns}
        data={enhancedData}
        isLoading={isLoadingRetailers}
      />
      <FABNewPaymentRequest />
    </>
  );
}

export default Clientes;
