import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { Models, Supplier } from 'types';
import { AddRetailerForm } from '../../types';
import {
  AddPaymentForm,
  LoadReceivablesRequest,
  LoadRetailerSettlementsRequest,
  LoadSelectedPaymentRequestRequest,
  MarkedConciliation,
  PaymentRequestWithConfirmationUser,
  SupplierState,
} from './supplier.types';

const initialState: SupplierState = {
  // isLoading: false,
  isLoadingPaymentRequests: false,
  isLoadingReceivables: false,
  isLoadingRetailers: false,
  isLoadingSelectedRetailer: false,
  isSavingRetailer: false,
  isLoadingSelectedRetailerBankAccount: false,
  isLoadingSelectedPaymentRequest: false,
  isCreatingPaymentRequest: false,
  isLoadingRetailerSettlements: false,
  isLoadingSettlements: false,
  isLoadingRetailerBankAccountTransactions: false,
  isLoadingRetailerSettlementsForConciliation: false,
  isLoadingSupplierBankAccountTransactions: false,
  isLoadingSupplierTransactionConciliations: false,
  isArchivingPaymentRequest: false,
  isDeletingPaymentRequest: false,
  retailers: [],
  invites: [],
  selectedReceivables: [],
  retailerReceivables: [],
  paymentRequests: [],
  paymentRequestDetails: [],
  selectedPaymentRequestDetails: [],
  paymentFormData: undefined,
  isCreatePaymentRequestSuccessOpen: false,
  isRetailerConciliationPanelOpen: false,
  isSavingRetailerConciliation: false,
  isCancelingPaymentRequest: false,
  isRunningCalcSuggestedValues: false,
};

export const supplierSlice = createSlice({
  name: 'supplier',
  initialState,
  reducers: {
    setIsLoadingReceivables: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingReceivables = payload;
    },
    setIsLoadingPaymentRequests: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isLoadingPaymentRequests = payload;
    },
    setIsLoadingRetailers: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingRetailers = payload;
    },
    setIsLoadingSelectedRetailer: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isLoadingSelectedRetailer = payload;
    },
    setIsSavingRetailer: (state, { payload }: PayloadAction<boolean>) => {
      state.isSavingRetailer = payload;
    },
    setIsLoadingSelectedRetailerBankAccount: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isLoadingSelectedRetailerBankAccount = payload;
    },
    setIsLoadingSelectedPaymentRequest: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isLoadingSelectedPaymentRequest = payload;
    },
    setIsCreatingPaymentRequest: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCreatingPaymentRequest = payload;
    },
    setIsLoadingRetailerSettlements: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isLoadingRetailerSettlements = payload;
    },
    setIsLoadingSettlements: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoadingSettlements = payload;
    },
    setIsLoadingRetailerBankAccountTransactions: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isLoadingRetailerBankAccountTransactions = payload;
    },
    setIsLoadingRetailerSettlementsForConciliation: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isLoadingRetailerSettlementsForConciliation = payload;
    },
    setIsArchivingPaymentRequest: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isArchivingPaymentRequest = payload;
    },
    setIsDeletingPaymentRequest: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isDeletingPaymentRequest = payload;
    },
    setIsLoadingSupplierBankAccountTransactions: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isLoadingSupplierBankAccountTransactions = payload;
    },
    setIsLoadingSupplierTransactionConciliations: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isLoadingSupplierTransactionConciliations = payload;
    },
    loadRetailers: () => {},
    setRetailers: (state, { payload }: PayloadAction<Models.Company[]>) => {
      state.retailers = payload;
    },
    setInvites: (
      state,
      { payload }: PayloadAction<Models.OnboardingInvite[]>
    ) => {
      state.invites = payload;
    },
    addRetailer: (
      _state,
      _action: PayloadAction<{
        data: AddRetailerForm;
        optinFile?: File;
        navigate: NavigateFunction;
      }>
    ) => {},
    loadSelectedRetailer: (_state, _action: PayloadAction<string>) => {},
    setSelectedRetailer: (
      state,
      { payload }: PayloadAction<Models.Company>
    ) => {
      state.selectedRetailer = payload;
    },
    setSelectedRetailerOwner: (
      state,
      { payload }: PayloadAction<Models.User>
    ) => {
      state.selectedRetailerOwner = payload;
    },
    loadReceivables: (
      _state,
      _action: PayloadAction<LoadReceivablesRequest>
    ) => {},

    setSelectedReceivables: (
      state,
      { payload }: PayloadAction<Models.Receivable[]>
    ) => {
      state.selectedReceivables = payload;
    },
    loadRetailerReceivables: (
      _state,
      _action: PayloadAction<LoadReceivablesRequest>
    ) => {},
    setRetailerReceivables: (
      state,
      { payload }: PayloadAction<Models.Receivable[]>
    ) => {
      state.retailerReceivables = payload;
    },
    loadSelectedPaymentRequest: (
      _state,
      _action: PayloadAction<LoadSelectedPaymentRequestRequest>
    ) => {},
    loadSelectedPaymentRequestDetails: (
      _state,
      _action: PayloadAction<LoadSelectedPaymentRequestRequest>
    ) => {},
    refreshSelectedPaymentRequest: (
      _state,
      _action: PayloadAction<string>
    ) => {},
    setSelectedPaymentRequest: (
      state,
      { payload }: PayloadAction<PaymentRequestWithConfirmationUser>
    ) => {
      state.selectedPaymentRequest = payload;
    },
    setSelectedPaymentRequestDetails: (
      state,
      { payload }: PayloadAction<Models.PaymentRequestDetail[]>
    ) => {
      state.selectedPaymentRequestDetails = payload;
    },

    loadPaymentRequests: (
      _state,
      _action: PayloadAction<Models.LoadPaymentRequestsForSupplierRequest>
    ) => {},
    setPaymentRequests: (
      state,
      { payload }: PayloadAction<Models.PaymentRequest[]>
    ) => {
      state.paymentRequests = payload;
    },
    setPaymentRequestDetails: (
      state,
      { payload }: PayloadAction<Models.PaymentRequestDetail[]>
    ) => {
      state.paymentRequestDetails = payload;
    },

    setPaymentFormData: (state, { payload }: PayloadAction<AddPaymentForm>) => {
      state.paymentFormData = payload;
    },
    clearPaymentFormData: (state) => {
      state.paymentFormData = undefined;
    },
    createPaymentRequest: (
      _state,
      _action: PayloadAction<{
        navigate: NavigateFunction;
      }>
    ) => {},
    setIsCreatePaymentRequestSuccessOpen: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCreatePaymentRequestSuccessOpen = payload;
    },
    loadSettlements: (
      _state,
      _action: PayloadAction<Models.LoadPaymentRequestDetailsForSupplierRequest>
    ) => {},
    loadRetailerSettlements: (
      _state,
      _action: PayloadAction<LoadRetailerSettlementsRequest>
    ) => {},
    loadRetailerBankAccountTransactions: (
      _state,
      _action: PayloadAction<Models.ListRetailerBankAccountTransactionsRequest>
    ) => {},
    setRetailerBankAccountTransactions: (
      state,
      { payload }: PayloadAction<Models.ListBankAccountTransactionResponse>
    ) => {
      state.retailerBankAccountTransactions = payload;
    },
    setSelectedRetailerBankAccount: (
      state,
      { payload }: PayloadAction<Models.BankAccount>
    ) => {
      state.selectedRetailerBankAccount = payload;
    },
    setRetailerTransactionConciliations: (
      state,
      { payload }: PayloadAction<Models.FinancialTransaction[]>
    ) => {
      state.retailerTransactionConciliations = payload;
    },

    setIsRetailerConciliationPanelOpen: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isRetailerConciliationPanelOpen = payload;
    },
    saveConciliationResult: (
      _state,
      _action: PayloadAction<{
        data: MarkedConciliation[];
        navigate: NavigateFunction;
        currentUrl: string;
      }>
    ) => {},
    loadRetailerSettlementsForConciliation: () => {},
    setIsSavingRetailerConciliation: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isSavingRetailerConciliation = payload;
    },
    cancelPaymentRequest: (
      _state,
      _action: PayloadAction<{
        request: Omit<Models.WebCancelPaymentRequestPayload, 'idempotencyKey'>;
        navigate: NavigateFunction;
      }>
    ) => {},
    setIsCancelingPaymentRequest: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isCancelingPaymentRequest = payload;
    },
    archivePaymentRequest: (
      _state,
      _action: PayloadAction<{
        request: Omit<Models.WebArchivePaymentRequestPayload, 'idempotencyKey'>;
        navigate: NavigateFunction;
      }>
    ) => {},
    deletePaymentRequest: (
      _state,
      _action: PayloadAction<{
        idPaymentRequest: string;
        idRetailer: string;
        navigate: NavigateFunction;
      }>
    ) => {},
    loadSupplierBankAccountTransactions: (
      _state,
      _action: PayloadAction<Models.ListSupplierBankAccountTransactionsRequest>
    ) => {},
    setSupplierBankAccountTransactions: (
      state,
      { payload }: PayloadAction<Models.ListBankAccountTransactionResponse>
    ) => {
      state.supplierBankAccountTransactions = payload;
    },
    setSupplierTransactionConciliations: (
      state,
      { payload }: PayloadAction<Models.FinancialTransaction[]>
    ) => {
      state.supplierTransactionConciliations = payload;
    },
    calcSuggestedValues: (
      _state,
      _action: PayloadAction<{
        opts: Supplier.CalcBalanceOpts;
        navigate: NavigateFunction;
      }>
    ) => {},
    setRetailerCalcSuggestedValues: (
      state,
      { payload }: PayloadAction<Supplier.CalcBalanceResult>
    ) => {
      state.retailerCalcSuggestedValues = payload;
    },
    setIsRunningCalcSuggestedValues: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isRunningCalcSuggestedValues = payload;
    },
  },
});

export const supplierActions = supplierSlice.actions;
export default supplierSlice.reducer;
