import {
  Alert,
  Box,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { Models } from 'types';
import { format } from '../../../helpers/format';
import { useAppSelector } from '../../../helpers/hooks';
import {
  getWarrantyExecutionDate,
  getWarrantyExecutionStatus,
} from '../../../helpers/payment-request-status';
import { getFilterUrl } from '../FornecedorListaPagamentos/get-filter-url';
import { ListaPagamentoFornecedor } from '../supplier.types';
import { DashboardCard } from './DashboardCard';

type OverdueCardProps = {
  paymentParams: Models.LoadPaymentRequestsForSupplierRequest;
};
export const OverdueCard = ({ paymentParams }: OverdueCardProps) => {
  let navigate = useNavigate();
  const { isLoadingPaymentRequests, retailers, paymentRequests } =
    useAppSelector((state) => state.supplier);

  const activeStatus: Models.PaymentRequestStatus[] = ['ativo'];

  const overduePaymentRequests = paymentRequests.filter(
    (p) =>
      activeStatus.includes(p.status) &&
      getWarrantyExecutionStatus(p) === 'overdue'
  );
  const sumOverduePayments = overduePaymentRequests
    .map(({ totalValue }) => totalValue)
    .reduce((acc, cur) => {
      acc += cur;
      return acc;
    }, 0);

  const enhancedData: ListaPagamentoFornecedor[] = overduePaymentRequests.map(
    (d) => {
      const retailer = retailers.find((r) => r.id === d.idRetailer);
      return {
        idRetailer: d.idRetailer,
        id: d.id,
        externalId: d.externalId,
        cliente: retailer ? retailer.fantasy ?? retailer.name : '...',
        numero: d.id,
        dataPedido: dayjs(d.dateadded).format('YYYYMMDD'),
        dataVencimento: d.dueDate,
        dataInicio: d.firstIncomingDate,
        operacao:
          d.effectType === 'garantia' ? 'Garantia' : 'Troca de Titularidade',
        valorTotal: d.totalValue,
        status: d.status,
        detalhes: 'pagamento-1',
        effectType: d.effectType,
        prioridade: d.effectPriority,
        statusExecucaoGarantia: getWarrantyExecutionStatus(d),
        dataExecucao: getWarrantyExecutionDate(d),
      };
    }
  );

  return (
    <DashboardCard
      borderColor="error.main"
      onClick={() =>
        navigate(
          getFilterUrl('/pagamentos', {
            ...paymentParams,
            statuses: ['ativo'],
            warrantyStatuses: ['overdue'],
          })
        )
      }
      title="Pagamentos vencidos a serem executados"
      value={
        isLoadingPaymentRequests
          ? '...'
          : sumOverduePayments > 0
          ? format(sumOverduePayments, 'money', { decimals: 0 })
          : '-'
      }
      color={sumOverduePayments > 0 ? 'error.main' : 'text.secondary'}
    >
      {isLoadingPaymentRequests ? (
        <Box
          p={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexGrow={1}
          height={1}
        >
          <CircularProgress color="error" />
        </Box>
      ) : (
        <Stack direction="column" p={2} gap={1}>
          <Alert severity="error" variant="filled">
            Os pagamentos abaixo serão exectuados caso não sejam cancelados.
            Verifique se o pagamento foi feito!
          </Alert>
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Cliente</TableCell>
                  <TableCell align="center">ID do pedido</TableCell>
                  <TableCell align="center">Vencimento</TableCell>
                  <TableCell align="center">Execução</TableCell>
                  <TableCell align="center">Valor</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {enhancedData.map((row) => (
                  <TableRow
                    hover
                    key={row.id}
                    sx={{
                      cursor: 'pointer',
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                    onClick={() =>
                      navigate(`/pagamentos/${row.idRetailer}/${row.id}`)
                    }
                  >
                    <TableCell component="th" scope="row">
                      {row.cliente}
                    </TableCell>
                    <TableCell align="center">{row.externalId}</TableCell>
                    <TableCell align="center">
                      {format(row.dataVencimento, 'date')}
                    </TableCell>
                    <TableCell align="center">
                      {format(row.dataExecucao, 'date')}
                    </TableCell>
                    <TableCell align="center">
                      {format(row.valorTotal, 'money')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Stack>
      )}
    </DashboardCard>
  );
};
