import { PayloadAction } from '@reduxjs/toolkit';
import { put, take } from 'redux-saga/effects';
import { Models, Supplier } from 'types';
import { WebRegistrarHelpers } from '../../data-helpers/WebRegistrarHelpers';
import { adminActions } from '../admin/admin.slice';
import { appActions } from '../app/app-slice';
import { notifierActions } from '../notifier/notifier-slice';
import { registrarActions } from './registrar-slice';

function* watchRefreshRetailerOptins() {
  while (true) {
    yield take(registrarActions.refreshRetailerOptins);
    yield put(adminActions.setIsLoading(true));
    try {
      const retailerOptin: Models.RetailerOptin =
        yield WebRegistrarHelpers.refreshRetailerOptins();
      yield put(appActions.setRetailerOptin(retailerOptin));
      yield put(adminActions.setIsLoading(false));
    } catch (e) {
      let message: string | undefined;
      const eAny = e as unknown as any;
      if (eAny.response && eAny.response.json) {
        const responseData: { message: string } = yield eAny.response.json();
        message = responseData.message;
      }
      const finalMessage =
        (message ?? (e as Error).message).trim() !== ''
          ? (message ?? (e as Error).message).trim()
          : 'Erro';
      yield put(appActions.setError(finalMessage));
      yield put(adminActions.setIsLoading(false));
    }
  }
}

function* watchGetRetailerOptin() {
  while (true) {
    const { payload: id }: PayloadAction<string> = yield take(
      registrarActions.getRetailerOptin
    );
    yield put(adminActions.setIsLoading(true));
    try {
      const retailerOptin: Models.RetailerOptin =
        yield WebRegistrarHelpers.getRetailerOptinById(id);
      yield put(appActions.setRetailerOptin(retailerOptin));
      yield put(adminActions.setIsLoading(false));
    } catch (e) {
      let message: string | undefined;
      const eAny = e as unknown as any;
      if (eAny.response && eAny.response.json) {
        const responseData: { message: string } = yield eAny.response.json();
        message = responseData.message;
      }
      const finalMessage =
        (message ?? (e as Error).message).trim() !== ''
          ? (message ?? (e as Error).message).trim()
          : 'Erro';
      yield put(appActions.setError(finalMessage));
      yield put(adminActions.setIsLoading(false));
    }
  }
}

function* watchUpdateRetailerOptin() {
  while (true) {
    const {
      payload: { id, body },
    }: PayloadAction<{ id: string; body: Supplier.PatchOptinPayload }> =
      yield take(registrarActions.updateRetailerOptin);
    yield put(adminActions.setIsLoading(true));
    try {
      const retailerOptin: Models.RetailerOptin =
        yield WebRegistrarHelpers.updateRetailerOptinById(id, body);
      yield put(appActions.setRetailerOptin(retailerOptin));
      yield put(adminActions.setIsLoading(false));
      yield put(
        notifierActions.enqueue({
          message:
            'Optin prorrogado com sucesso, a atualização pode levar alguns minutos.',
          options: {
            variant: 'success',
          },
        })
      );
    } catch (e) {
      let message: string | undefined;
      const eAny = e as unknown as any;
      if (eAny.response && eAny.response.json) {
        const responseData: { message: string } = yield eAny.response.json();
        message = responseData.message;
      }
      const finalMessage =
        (message ?? (e as Error).message).trim() !== ''
          ? (message ?? (e as Error).message).trim()
          : 'Erro';
      yield put(appActions.setError(finalMessage));
      yield put(adminActions.setIsLoading(false));
    }
  }
}

function* watchCreateRetailerOptin() {
  while (true) {
    yield take(registrarActions.createRetailerOptin);
    yield put(adminActions.setIsLoading(true));
    try {
      const created: Models.RetailerOptin =
        yield WebRegistrarHelpers.createRetailerOptin();
      yield put(appActions.setRetailerOptin(created));
      yield put(adminActions.setIsLoading(false));
    } catch (e) {
      let message: string | undefined;
      const eAny = e as unknown as any;
      if (eAny.response && eAny.response.json) {
        const responseData: { message: string } = yield eAny.response.json();
        message = responseData.message;
      }
      const finalMessage =
        (message ?? (e as Error).message).trim() !== ''
          ? (message ?? (e as Error).message).trim()
          : 'Erro';
      yield put(appActions.setError(finalMessage));
      yield put(adminActions.setIsLoading(false));
    }
  }
}

function* watchCancelRetailerOptin() {
  while (true) {
    const { payload: id }: PayloadAction<string> = yield take(
      registrarActions.cancelRetailerOptin
    );
    yield put(adminActions.setIsLoading(true));
    try {
      yield WebRegistrarHelpers.cancelRetailerOptin(id);
      yield put(
        notifierActions.enqueue({
          message:
            'Solicitação de opt-out criada com sucesso, a atualização pode levar alguns minutos.',
          options: {
            variant: 'success',
          },
        })
      );
      yield put(adminActions.setIsLoading(false));
    } catch (e) {
      let message: string | undefined;
      const eAny = e as unknown as any;
      if (eAny.response && eAny.response.json) {
        const responseData: { message: string } = yield eAny.response.json();
        message = responseData.message;
      }
      const finalMessage =
        (message ?? (e as Error).message).trim() !== ''
          ? (message ?? (e as Error).message).trim()
          : 'Erro';
      yield put(appActions.setError(finalMessage));
      yield put(adminActions.setIsLoading(false));
    }
  }
}

export default [
  watchRefreshRetailerOptins,
  watchGetRetailerOptin,
  watchCreateRetailerOptin,
  watchCancelRetailerOptin,
  watchUpdateRetailerOptin,
];
