import { Models, QITech, RPC, Service } from 'types';
import { apiCall, rpcCall } from '../helpers/api';

const service: Service = 'supplier';
const resource = '/web/bank-account';

export class WebBankAccountHelpers {
  public static *createAccount() {
    const rpcCallResult: RPC.Call<void, Models.WebCreateBankAccountResponse> =
      yield rpcCall({
        service,
        resource: `${resource}/create`,
        kyOpts: {
          method: 'post',
        },
      });
    return rpcCallResult;
  }

  public static *listRetailerTransactions(
    payload: Models.ListRetailerBankAccountTransactionsRequest
  ) {
    const rpcCallResult: RPC.Call<
      void,
      Models.ListBankAccountTransactionResponse
    > = yield rpcCall({
      service,
      resource: `${resource}/list-retailer-transactions`,
      payload,
      kyOpts: {
        method: 'post',
      },
    });
    return rpcCallResult;
  }

  public static *listSupplierTransactions(
    payload: Models.ListSupplierBankAccountTransactionsRequest
  ) {
    const rpcCallResult: RPC.Call<
      void,
      Models.ListBankAccountTransactionResponse
    > = yield rpcCall({
      service,
      resource: `${resource}/list-supplier-transactions`,
      payload,
      kyOpts: {
        method: 'post',
      },
    });
    return rpcCallResult;
  }

  public static *searchPixKey(pixKey: string) {
    const rpcCallResult: RPC.Call<
      void,
      Models.ListBankAccountTransactionResponse
    > = yield rpcCall({
      service,
      resource: `${resource}/pix-key/${encodeURIComponent(pixKey)}`,
    });
    return rpcCallResult;
  }

  public static *transferToTargetAccount(
    payload: Models.TransferToTargetAccountRequest
  ) {
    const rpcCallResult: RPC.Call<void, void> = yield rpcCall({
      service,
      resource: `${resource}/transfer-to-target-account`,
      payload,
      kyOpts: {
        method: 'post',
      },
    });
    return rpcCallResult;
  }

  public static *internalTransferToTargetAccount(
    payload: Models.InternalTransferToTargetAccountRequest
  ) {
    const rpcCallResult: RPC.Call<void, void> = yield rpcCall({
      service,
      resource: `${resource}/internal-transfer-to-target-account`,
      payload,
      kyOpts: {
        method: 'post',
      },
    });
    return rpcCallResult;
  }

  public static *getAccountInfo() {
    const rpcCallResult: RPC.Call<void, QITech.GetAccountInfoResponse> =
      yield rpcCall({
        service: 'supplier',
        resource: '/web/bank-account',
      });
    return rpcCallResult;
  }

  public static *getPixLimitsUsage() {
    const rpcCallResult: RPC.Call<void, QITech.GetPixLimitUsageResponse> =
      yield rpcCall({
        service: 'supplier',
        resource: '/web/bank-account/pix-limits-usage',
      });
    return rpcCallResult;
  }

  public static *requestNewPixLimits(payload: Models.NewPixLimitsRequest) {
    const rpcCallResult: RPC.Call<void, QITech.CreatePixLimitRequestResponse> =
      yield rpcCall({
        service: 'supplier',
        resource: '/web/bank-account/request-new-pix-limits',
        payload,
        kyOpts: {
          method: 'post',
        },
      });
    return rpcCallResult;
  }

  public static getByCompanyId = async (
    idCompany: string
  ): Promise<Models.BankAccount | undefined> => {
    try {
      return await apiCall<Models.BankAccount>({
        service,
        resource: `${resource}/get-by-company-id/${idCompany}`,
      });
    } catch (e) {
      if ((e as unknown as any)?.response?.status === 404) return undefined;
      throw e;
    }
  };

  public static listRetailerTransactionConciliations = async (
    payload: Models.ListRetailerBankAccountTransactionsRequest
  ): Promise<Models.FinancialTransaction[]> => {
    const result = await apiCall<Models.FinancialTransaction[]>({
      service,
      resource: '/web/bank-account/list-retailer-transaction-conciliations',
      payload,
      kyOpts: {
        method: 'post',
      },
    });
    return result;
  };

  public static listSupplierTransactionConciliations = async (
    payload: Models.ListSupplierBankAccountTransactionsRequest
  ): Promise<Models.FinancialTransaction[]> => {
    const result = await apiCall<Models.FinancialTransaction[]>({
      service,
      resource: '/web/bank-account/list-supplier-transaction-conciliations',
      payload,
      kyOpts: {
        method: 'post',
      },
    });
    return result;
  };

  public static saveConciliationResult = async (
    payload: Models.SaveConciliationResultRequest
  ) => {
    await apiCall({
      service,
      resource: '/web/bank-account/save-conciliation-result',
      payload,
      kyOpts: {
        method: 'post',
      },
    });
  };
}
