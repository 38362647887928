import { PayloadAction } from '@reduxjs/toolkit';
import { put, select, take } from 'redux-saga/effects';
import { Models } from 'types';
import { WebOnboardingHelpers } from '../../data-helpers/WebOnboardingHelpers';
import { WebUserHelpers } from '../../data-helpers/WebUserHelpers';
import { checkOperatingHours } from '../../helpers/operating-hours';
import { InitDBUserRequest } from '../../types';
import { companySelectActions } from '../company-select/company-select-slice';
import { devToolsActions } from '../dev-tools/dev-tools-slice';
import { notifierActions } from '../notifier/notifier-slice';
import { settingsActions } from '../settings/settings-slice';
import { appActions } from './app-slice';
import { AppState, OperatingHours } from './app.types';

function* watchInit() {
  while (true) {
    yield take(appActions.init);
    const theme = localStorage.getItem('br.com.paguerecebivel.theme') as
      | 'dark'
      | 'light';
    if (theme !== undefined && theme !== null)
      yield put(appActions.setTheme(theme));

    const isLocalApi =
      (localStorage.getItem('isLocalApi') ?? 'false') === 'true';
    yield put(devToolsActions.setIsLocalApi(isLocalApi));

    const skipRegistrar =
      (localStorage.getItem('skipRegistrar') ?? 'false') === 'true';
    yield put(devToolsActions.setSkipRegistrar(skipRegistrar));
  }
}

function* watchErrors() {
  while (true) {
    const { payload: message } = yield take(appActions.setError);
    yield put(
      notifierActions.enqueue({
        message,
        options: {
          variant: 'error',
        },
      })
    );
  }
}

function* watchInitDBUser() {
  while (true) {
    const {
      payload: { user: authUser, idInvite },
    }: PayloadAction<InitDBUserRequest> = yield take(appActions.initDBUser);
    yield put(appActions.startLoading());

    try {
      const operatingHours: OperatingHours = yield checkOperatingHours();
      yield put(appActions.setOperatingHours(operatingHours));
    } catch (e) {
      console.error(e);
    }

    try {
      if (!authUser.email) throw new Error('An error occurred - E00');
      const {
        user,
        company,
        supplier,
        invite,
        onboardingPhase,
        supplierData,
        bankAccount,
        retailerOptin,
        targetBankAccount,
        supplierWarrantyConfig,
        supplierOwnershipAssignmentConfig,
        supplierPersonalizationConfig,
        supplierUserGroup,
      }: Models.WebInitResponse = yield WebOnboardingHelpers.init({
        email: authUser.email,
        emailVerified: authUser.email_verified ?? false,
        idInvite,
      });
      if (user) {
        yield put(appActions.setDBUser(user));
        if (user.userRole === 'adm' || user.userRole === 'dev')
          yield put(companySelectActions.loadCompanies());
      }
      const allowedPhases: Models.WebOnboardingPhase[] = [
        'approved',
        'complete',
        // 'email not verified',
        'incomplete',
      ];
      if (company && allowedPhases.includes(onboardingPhase))
        yield put(appActions.setCompany(company));
      if (supplier && allowedPhases.includes(onboardingPhase))
        yield put(appActions.setSupplier(supplier));
      if (bankAccount) yield put(appActions.setBankAccount(bankAccount));
      if (retailerOptin) yield put(appActions.setRetailerOptin(retailerOptin));
      if (targetBankAccount && allowedPhases.includes(onboardingPhase))
        yield put(appActions.setTargetBankAccount(targetBankAccount));
      if (supplierData && allowedPhases.includes(onboardingPhase))
        yield put(appActions.setSupplierData(supplierData));
      if (invite) yield put(appActions.setInvite(invite));
      if (supplierUserGroup)
        yield put(appActions.setSupplierUserGroup(supplierUserGroup));

      if (supplierWarrantyConfig)
        yield put(
          settingsActions.setSupplierWarrantyConfig(supplierWarrantyConfig)
        );
      if (supplierOwnershipAssignmentConfig)
        yield put(
          settingsActions.setSupplierOwnershipAssignmentConfig(
            supplierOwnershipAssignmentConfig
          )
        );

      if (supplierPersonalizationConfig)
        yield put(
          settingsActions.setSupplierPersonalizationConfig(
            supplierPersonalizationConfig
          )
        );
      yield put(appActions.setOnboardingPhase(onboardingPhase));
      yield put(appActions.finishLoading());
      yield put(appActions.setIsInitializing(false));
    } catch (e) {
      console.error(e);
      yield put(appActions.setOnboardingPhase('unexpected'));
      yield put(appActions.setError((e as Error).message));
      yield put(appActions.finishLoading());
    }
  }
}

function* watchChangeUserCompany() {
  while (true) {
    yield put(appActions.setIsInitializing(true));
    const { payload: idCompany } = yield take(appActions.changeUserCompany);
    const { dbUser }: AppState = yield select((state) => state.app);
    if (dbUser?.id) yield WebUserHelpers.patch(dbUser?.id, { idCompany });
    window.location.href = '/';
  }
}

function* watchSetTheme() {
  while (true) {
    const { payload: theme }: PayloadAction<string> = yield take(
      appActions.setTheme
    );
    if (theme !== undefined)
      localStorage.setItem('br.com.paguerecebivel.theme', theme);
  }
}

export default [
  watchInit,
  watchErrors,
  watchInitDBUser,
  watchChangeUserCompany,
  watchSetTheme,
];
